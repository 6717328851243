//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-824:_9024,_6292,_2220,_9348,_524,_9884,_8308,_1224;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-824')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-824', "_9024,_6292,_2220,_9348,_524,_9884,_8308,_1224");
        }
      }catch (ex) {
        console.error(ex);
      }